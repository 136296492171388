<!-- Settings -->
<template>
    <div  v-loading="loading">       
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group >
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                    <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                    <b-input-group-append>
                      <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                    </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>
                    
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">
              <el-table-column fixed align="center" label='PO Number' prop="sku" sortable width="120" >
                <template slot-scope="scope">
                <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewPurchase(scope.row.id)">{{(scope.row.id) ? scope.row.id : ""}}</a></el-tag>
                </template>
              </el-table-column>
               <el-table-column label="Product #" sortable width="120" >
                <template slot-scope="scope">
                <span>{{(scope.row.product) ? scope.row.product.sku : ""}}</span>
                </template>
              </el-table-column>    
              <el-table-column label="Product Name" sortable  min-width="250">
                <template slot-scope="scope">
                <span>{{(scope.row.product) ? scope.row.product.name : ""}}</span>
                </template>
              </el-table-column>  
               <el-table-column label="Supplier" sortable  >
                <template slot-scope="scope">
                <span>{{(scope.row.supplier) ? scope.row.supplier.company_name : ""}}</span>
                </template>
              </el-table-column>             
              <el-table-column label="Stock Quantity" prop="" sortable width="150" align="center">
                <template slot-scope="scope">
                {{scope.row.product.stock_quantity?scope.row.product.stock_quantity:0}} 
                </template>
              </el-table-column>
              <el-table-column label="Max Quantity" sortable width="150" align="center">
                <template slot-scope="scope">
                <span>{{scope.row.product.max_quantity?scope.row.product.max_quantity:0}}</span>
                </template>
              </el-table-column>    
             <el-table-column label="Reorder" sortable width="100" >
                <template slot-scope="scope">
                <span>{{scope.row.reorder}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
          <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {downloadPdf,deletePurchaseorder,getRequestPO} from "@/api/purchaseorder";
import SendEmail from '@/components/SendEmail'
  export default {
    name: "list-purchase-order",
    data() {
      return {
        list: [],
        items: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        view_type: 'purchaseorder'
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },        
        {
          title: "Purchase Order",
        },       
      ]);
    },
    created(){
      this.fetchData(1);
    },
    methods:{
      /*updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},*/
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
         if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        if(this.selectedWebsite){
          params.selected_website = this.selectedWebsite;
        }
        getRequestPO(params).then(response => {
         
          this.list = response.data.data.data
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
        })
      },

        getcustomername(customer){
            return customer.first_name+' '+customer.last_name;
        },

     onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
        createPurchaseOrder(){
        this.$router.push({path:'/purchase-order/create'})
      },
      viewPurchase(purchase_id){
         this.$router.push({path:'/purchase-order/view/'+purchase_id})
      },
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/purchase-order/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/purchase-order/edit/'+row.id})
          }
           else if (evt === 4){            
            this.loading=true;
            downloadPdf(row.id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'purchaseorder.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          }
          else if (evt === 3){  
         console.log(row);     
                let customer = row.supplier;
                let order_id = row.id;
                let type = this.view_type;

            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          }
          else if (evt === 5){
            this.$bvModal.msgBoxConfirm('Are you sure you want to delete ?', {
              title: 'Please Confirm',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){
                this.loading = true;
               deletePurchaseorder(row.id).then(response => {
                  if(response.data.status){
                      this.fetchData(1)
                      this.$showResponse('success', response.data.message);
                  }
                  this.loading = false;
                })
              }else{
                return false;
              }
            })
          }
        },
    }
  }
</script>